import React from 'react'
import ContactSection from '~sections/contact/ContactOne/ContactSection'
import FooterSix from '~sections/Home/footerSix'
import { PageWrapper } from '~components/Core'
// import HeaderButton from '~sections/digital/Header'
// import AboutData from '~sections/about/content'
import TitleSection from '~sections/contact/ContactOne/titleSection'
import { graphql } from 'gatsby'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton className='d-none d-sm-flex' children='Ajukan Survei' />
  // ),
  darkLogo: false,
}

export default function SurveyRequest({
  pageContext: { electricalBills, electricalPowers },
}) {
  return (
    <PageWrapper headerConfig={header}>
      <TitleSection />
      {/* <AboutData /> */}
      <ContactSection
        electricalBills={electricalBills}
        electricalPowers={electricalPowers}
      />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
