import React from 'react'
import BreadCrumb from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container } from 'react-bootstrap'
import { SuperTag } from '~components'
import { Images } from '~data'
import { useTranslation } from 'react-i18next'

export default function ServiceTitle({ colClasses, title, text, ...rest }) {
  const { t } = useTranslation()
  return (
    <BreadCrumb
      // style={{
      //   backgroundImage: `url(${Images.DigiTalagency.heroImg})`,
      //   height: `100%`,
      // }}
      backgroundColor='#f3f4f6'
      {...rest}
    >
      <Container style={{ height: '80%' }}>
        <Row className='row justify-content-center text-center'>
          <Col className={colClasses ? colClasses : 'col-xl-5'}>
            <BreadCrumb.Title as='h2'>
              {t('title', { ns: 'survey-request' })}
            </BreadCrumb.Title>
            <BreadCrumb.Text>
              <SuperTag
                value={t('subtitle', { ns: 'survey-request' })}
                style={{ fontSize: '16px', marginBottom: '-300px' }}
              />
            </BreadCrumb.Text>
          </Col>
        </Row>
      </Container>
      {/* <BreadCrumb.Image>
        <Img
          className='w-100'
          src='../../../assets/image/mixed/inner-banner-shape.png'
          alt='shape'
          layout='fullWidth'
          placeholder='blurred'
        />
      </BreadCrumb.Image> */}
    </BreadCrumb>
  )
}
