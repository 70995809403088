import React from 'react'
import Contact from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { navigate } from 'gatsby'
import Swal from 'sweetalert2'
import { Link } from '~components'
import { Link as IntlLink, useTranslation } from 'gatsby-plugin-react-i18next'
import _ from 'lodash'

export default function ContactOne({ electricalBills, electricalPowers }) {
  const { t } = useTranslation()

  const inputData = async values => {
    try {
      await axios.post(
        // `${process.env.API_BASE_URL}/v1/rental_applications/web`
        // `https://api-staging.sobatrp.com/v1/rental_applications/web `
        `${process.env.API_BASE_URL}/v1/rental_applications/web`,
        {
          address: values.address,
          latitude: 0,
          longitude: 0,
          fullName: values.fullName,
          phoneNumber: _.toString(values.msisdn),
          email: values.email,
          electricalBill: values.bill,
          electricalPower: values.power,
        },
      )
      Swal.fire({
        icon: 'success',
        title: `${t('success.succesSend', { ns: 'survey-request' })}`,
        text: `${t('success.successText', { ns: 'survey-request' })}`,
        confirmButtonText: `<div>${t('success.back', {
          ns: 'survey-request',
        })}</div>`,
        footer: `<a href="/">${t('success.backtoHome', {
          ns: 'survey-request',
        })}</a>`,
      }).then(result => {
        if (result.isConfirmed) {
          navigate('/survey-request')
        }
      })
      //
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Maaf mohon periksa data yang anda masukan',
        text: 'Ada yang salah dalam input anda',
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      fullName: '',
      msisdn: '',
      bill: '',
      power: '',
      address: '',
      email: '',
      toggle: false,
      toggleReady: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .max(50, `${t('error.fullName100', { ns: 'survey-request' })}`)
        .min(3, `${t('error.fullName100', { ns: 'survey-request' })}`)
        .matches(
          /^[a-zA-Z ]*$/,
          `${t('error.fullNameAlpha', { ns: 'survey-request' })}`,
        )
        .required(`${t('error.requiredFullname', { ns: 'survey-request' })}`),
      msisdn: Yup.string()
        .max(15, `${t('error.msisdn15', { ns: 'survey-request' })}`)
        .min(4, `${t('error.msisdn15', { ns: 'survey-request' })}`)
        .matches(
          /^[0-9]*$/,
          `${t('error.msisdnNumber', { ns: 'survey-request' })}`,
        )
        .required(`${t('error.requiredMsisdn', { ns: 'survey-request' })}`),
      bill: Yup.string().required(
        `${t('error.requiredBill', { ns: 'survey-request' })}`,
      ),
      power: Yup.string().required(
        `${t('error.requiredPower', { ns: 'survey-request' })}`,
      ),
      address: Yup.string()
        .min(4, `${t('error.minAddress', { ns: 'survey-request' })}`)
        .required(`${t('error.requiredAddress', { ns: 'survey-request' })}`),
      email: Yup.string()
        .email(`${t('error.emailNotCompetable', { ns: 'survey-request' })}`)
        .required(`${t('error.requiredMail', { ns: 'survey-request' })}`),
      toggle: Yup.boolean()
        .required(`${t('error.needAgree', { ns: 'survey-request' })}`)
        .oneOf([true], `${t('error.needAgree', { ns: 'survey-request' })}`),
      toggleReady: Yup.boolean()
        .required(`${t('error.wantAgree', { ns: 'survey-request' })}`)
        .oneOf([true], `${t('error.wantAgree', { ns: 'survey-request' })}`),
    }),
    onSubmit: async values => {
      if (values.toggle === false) {
        Swal.fire({
          icon: 'error',
          title: 'Silakan menyetujui persyaratan terlebih dahulu',
        })

        // return values === null
      } else {
        inputData(values)
      }
    },
  })

  return (
    <Contact backgroundColor='#f3f4f6'>
      <Container>
        <Row>
          <Col className='col-xl-12 col-lg-12'>
            <Contact.From>
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col xs='12' className='col-lg-6 mb-4'>
                    <div className='form-floating'>
                      <input
                        className='form-control'
                        placeholder='Leave a comment here'
                        id='fullName'
                        name='fullName'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullName}
                      />
                      <label htmlFor='floatinginput'>
                        {t('form.fullNameLabel', { ns: 'survey-request' })}
                      </label>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {formik.touched.fullName && formik.errors.fullName ? (
                        <div>{formik.errors.fullName}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col xs='12' className='col-lg-6 mb-4'>
                    <div className='input-group has-validation'>
                      <span
                        className='input-group-text'
                        id='validationTooltipUsernamePrepend'
                      >
                        +62
                      </span>
                      <div className='form-floating'>
                        <input
                          className='form-control'
                          placeholder='Leave a comment here'
                          // maxlength='12'
                          // max={10}
                          id='msisdn'
                          name='msisdn'
                          type='number'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.msisdn}
                        />
                        <label htmlFor='floatinginput'>
                          {t('form.msisdn', { ns: 'survey-request' })}
                        </label>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {formik.touched.msisdn && formik.errors.msisdn ? (
                        <div>{formik.errors.msisdn}</div>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col xs='12' className='col-lg-4 mb-4'>
                    <div className='input-group has-validation'>
                      <span
                        className='input-group-text'
                        id='validationTooltipUsernamePrepend'
                      >
                        +62
                      </span>
                      <div className='form-floating'>
                        <input
                          className='form-control'
                          placeholder='Leave a comment here'
                          // maxlength='12'
                          // max={10}
                          id='whatsapp'
                          name='whatsapp'
                          type='number'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.whatsapp}
                        />
                        <label htmlFor='floatinginput'>Nomor Whatsapp</label>
                      </div>
                    </div>
                    {formik.touched.whatsapp && formik.errors.whatsapp ? (
                      <div>{formik.errors.whatsapp}</div>
                    ) : null}
                  </Col> */}

                  <Col xs='12' className='col-lg-4 mb-4'>
                    <div className='form-floating'>
                      <input
                        className='form-control'
                        placeholder='Leave a comment here'
                        id='email'
                        name='email'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      <label htmlFor='floatinginput'>
                        {t('form.email', { ns: 'survey-request' })}
                      </label>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {formik.touched.email && formik.errors.email ? (
                        <div>{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col xs='12' className='col-lg-4 mb-4'>
                    {/* <div className='form-floating'> */}
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      id='power'
                      name='power'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.power}
                    >
                      <option hidden value={''}>
                        {t('form.electricity', {
                          ns: 'survey-request',
                        })}
                      </option>
                      {electricalPowers &&
                        electricalPowers.map(ep => (
                          <option value={ep.value} key={`ep#${ep.value}`}>
                            {ep.label}
                          </option>
                        ))}
                    </select>
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {formik.touched.power && formik.errors.power ? (
                        <div>{formik.errors.power}</div>
                      ) : null}{' '}
                    </div>
                  </Col>

                  <Col xs='12' className='col-lg-4 mb-4'>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      id='bill'
                      name='bill'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bill}
                    >
                      <option hidden value={''}>
                        {t('form.bill', { ns: 'survey-request' })}
                      </option>
                      {electricalBills &&
                        electricalBills.map(eb => (
                          <option value={eb.value} key={`eb#${eb.value}`}>
                            {eb.label}
                          </option>
                        ))}
                    </select>
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {formik.touched.bill && formik.errors.bill ? (
                        <div>{formik.errors.bill}</div>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs='12' className='col-lg-12 mb-4'>
                    <div className='form-floating'>
                      <textarea
                        className='form-control'
                        placeholder='Leave a comment here'
                        id='address'
                        name='address'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      <label htmlFor='floatingTextarea3'>
                        {t('form.address', { ns: 'survey-request' })}
                      </label>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {formik.touched.address && formik.errors.address ? (
                        <div>{formik.errors.address}</div>
                      ) : null}
                    </div>
                  </Col>
                  <Col md='12' className='col-lg-12'>
                    <div className='col-md-10 col-lg-7 col-md-6 col-xl-8 pt-3'>
                      <div className='form-check d-flex align-items-center'>
                        <input
                          className='form-check-input bg-white float-none mt-0'
                          type='checkbox'
                          defaultValue
                          id='flexCheckDefault'
                          name='toggle'
                          value={formik.values.toggle}
                          // checked={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexCheckDefault'
                        >
                          {t('readTerms', { ns: 'footer' })}{' '}
                          <IntlLink
                            as={Link}
                            to={`/privacy`}
                            style={{ color: 'blue' }}
                          >
                            {t('PrivacyPolicy', { ns: 'footer' })}
                          </IntlLink>
                          ,{' '}
                          <IntlLink
                            as={Link}
                            to={`/cookies`}
                            style={{ color: 'blue' }}
                          >
                            {t('CookiesPolicy', { ns: 'footer' })}
                          </IntlLink>
                          , {t('and', { ns: 'footer' })}{' '}
                          {/* <IntlLink as={Link} to={`/terms-conditions-survey`}>
                            {t('TermsConditionSurvey', { ns: 'footer' })}
                          </IntlLink>
                          ,{' '} */}
                          <IntlLink
                            as={Link}
                            to={`/terms-conditions-sobatrp`}
                            style={{ color: 'blue' }}
                          >
                            {t('TermsConditionSurvey', { ns: 'footer' })}
                          </IntlLink>
                          <div
                            style={{
                              color: 'red',
                              fontSize: '12px',
                            }}
                          >
                            {formik.touched.toggle && formik.errors.toggle ? (
                              <div>{formik.errors.toggle}</div>
                            ) : null}
                          </div>
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col xs='12' className='col-lg-12'>
                    <div className='col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3'>
                      <div className='form-check d-flex align-items-center'>
                        <input
                          className='form-check-input bg-white float-none mt-0 form-label'
                          type='checkbox'
                          defaultValue
                          id='flexCheckDefault-1'
                          name='toggleReady'
                          value={formik.values.toggleReady}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <label
                          className='form-check-label form-label'
                          htmlFor='flexCheckDefault-1'
                        >
                          {t('believeData', { ns: 'survey-request' })}
                          <div style={{ color: 'red', fontSize: '12px' }}>
                            {formik.touched.toggleReady &&
                            formik.errors.toggleReady ? (
                              <div>{formik.errors.toggleReady}</div>
                            ) : null}
                          </div>
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xs='12' className='col-lg-12'>
                    <Row className='align-items-center mt-3'>
                      <div className='col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3'></div>
                      <Col
                        xs='12'
                        className='col-md-4 col-lg-5 col-xl-4 text-md-end pt-3'
                      >
                        <Contact.Button type='submit'>
                          {t('form.submit', { ns: 'survey-request' })}
                        </Contact.Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </Contact.From>
          </Col>
        </Row>
      </Container>
    </Contact>
  )
}
